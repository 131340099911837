<template>
	<edit-template class="add" @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div style="min-height: calc(100vh - 246rem)">
      <el-divider content-position="left"><b><h3>基础信息</h3></b></el-divider>
      <el-form :model="form" :rules="rules" ref="form" label-width="150rem" inline="" style="width: 530rem">
        <el-form-item label="所属校区" prop="school_id">
          <el-select :popper-append-to-body="false" v-model="form.school_id">
            <el-option v-for="(item,index) in userInfo.school_arr" :value="index" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校历名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="有效期开始时间" prop="start_at">
          <el-date-picker v-model="form.start_at" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="onTimeChange($event)" :disabled="events_form.events.length!==0" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="有效期结束时间" prop="end_at">
          <el-date-picker v-model="form.end_at" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="onTimeChange($event)" :disabled="events_form.events.length!==0 || !form.start_at" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="应用年级" prop="grade_id">
          <el-select :popper-append-to-body="false" v-model="form.grade_id" placeholder="请选择">
            <el-option v-for="item in gradeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用角色类型" prop="role_type">
          <el-select :popper-append-to-body="false" v-model="form.role_type" placeholder="请选择">
            <el-option v-for="item in roleTypeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-divider content-position="left"><b><h3>添加事件</h3></b></el-divider>
      <el-form :model="events_form" ref="events">
        <div class="loading" v-show="loadingEvent"><i class="el-icon-loading"></i> 加载中</div>
        <t-big-data-select :options="timeOptions" :config="{
        value:'value',
        label:'label',
        children:'children',
      }">
          <div class="events" v-show="!loadingEvent">
            <div class="event" :key="item.key" v-for="(item,index) in events_form.events">
              <div class="eventInfo">
                <el-form-item class="form-item" :prop="'events.'+index+'.startTime'" :rules="{required:true,message:'请选择开始时间'}" :key="'events_start'+index">
                  <!--                <el-cascader
                                      v-model="item.startTime"
                                      @change="changeTimes(0,$event,index)"
                                      :options="startTimes"></el-cascader>-->
                  <t-big-data-select-item v-model="item.startTime" placeholder="请选择" @click="getSelectData($event,0,index)" @change="changeTimes(0,$event,index)"></t-big-data-select-item>
                </el-form-item>
                <span>到</span>
                <el-form-item class="form-item" :prop="'events.'+index+'.endTime'" :rules="{required:true,message:'请选择结束时间'}" :key="'events_end'+index">
                  <!--                <el-cascader
                                      v-model="item.endTime"
                                      :options="endTimes[index]"></el-cascader>-->
                  <t-big-data-select-item v-model="item.endTime" placeholder="请选择" @click="getSelectData($event,1,index)"></t-big-data-select-item>
                </el-form-item>
                <span>发生事件</span>
                <el-form-item style="flex: 1" class="form-item" :prop="'events.'+index+'.name'" :rules="[{required:true,message:'请输入事件名称'},{max:12,message:'事件名称不可超过12字'}]">
                  <el-input style="width: 100% !important;" placeholder="请输入名称" v-model="item.name"></el-input>
                </el-form-item>
              </div>
              <div class="eventBtn">
                <div class="btn del-btn" @click.prevent="onEventHandle(0,index)"><i class="el-icon-minus"></i></div>
                <div class="btn add-btn" @click="onEventHandle(1,index)"><i class="el-icon-plus"></i></div>
              </div>
            </div>
            <div v-if="events_form.events.length===0" style="margin: 20rem">
              <el-button icon="el-icon-plus" @click.prevent="onEventHandle(-1,0)">添加事件</el-button>
            </div>
          </div>
        </t-big-data-select>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  computed:{
    ...mapGetters(["userInfo"])
  },
  data() {
    let validateDate = (rule,value,callback)=>{
      let {start_at,end_at} = this.form;
      if (!!start_at && !!end_at){
        let start = new Date(start_at).getTime(), end = new Date(end_at).getTime();
        if (start >= end) {
          callback(new Error("开始时间必须小于结束时间"))
        }else callback();
      }else callback();
    }
    return {
      timeOptions:[],
      id:null,
      // form表单
      form:{
        name:null,
        start_at:null,
        end_at:null,
        grade_id:null,
        role_type:null,
        school_id:null
      },
      loadingEvent:false,
      loadingWatch: false,
      // 年级选项
      gradeOptions:[],
      // 角色选项
      roleTypeOptions:[
        {
          id:1,
          name:"学生"
        },
        {
          id:2,
          name:"教职工"
        }
      ],
      startTimes:[],
      endTimes:[],
      // 规则定义
      rules:{
        school_id:[{required:true,message:"请选择校区"}],
        name:[{required:true,message:"请填写校历名称"}],
        start_at:[{required:true,type:"string",message:"请选择开始时间"},{validator:validateDate}],
        end_at:[{required:true,type:"string",message:"请选择结束时间"},{validator:validateDate}],
        grade_id:[{required:true,message:"请选择应用年级"}],
        role_type:[{required:true,message:"请选择角色类型"}],
      },
      events_form:{
        events:[
        ]
      }
    }
  },
  watch: {
    'form.grade_id'() {
      this.getEventData()
    },
    'form.school_id'() {
      this.getEventData()
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.init()
  },

  methods: {
    // 获取选择框数据
    getSelectData(e,type,index){
      if (type === 0)
        this.timeOptions = this.startTimes;
      else
        this.timeOptions = this.endTimes[index];
    },
    // 数据初始化
    init() {
      // 获取年级数据
      this.$utils.commonApi.getGradeList().then(res => {
        this.gradeOptions = res.data.data;
      });
      // 获取初始数据
      this.loadingEvent = true;
      this.$_axios.get("/school-calendar/" + this.id).then(res => {
        let {data} = res.data, events = data.events;
        this.form = data;
        this.form.school_id += "";
        this.form.grade_id += "";
        this.onTimeChange(0,true, ()=> {
          this.events_form.events = events.map((item,index) => {
            let {
              calendar_id,
              end_day,
              end_section,
              end_week,
              id,
              name,
              start_day,
              start_section,
              start_week
            } = item;
            let startTime = [Number(start_week), Number(start_day), Number(start_section)];
            let endTime =  [Number(end_week), Number(end_day), Number(end_section)];
            this.changeTimes(0,startTime,index,true)
            return {
              startTime,
              endTime,
              name: name,
            };
          })
          // this.events_form.events = JSON.parse(JSON.stringify(this.events_form.events));

          this.loadingEvent = false;
        })

      }).catch(err=>this.loadingEvent=false)
    },
    /**
     * @description 事件操作
     * @param type 类型 0 删除 1 添加
     * @param index 下标
     */
    onEventHandle(type, index) {
      if (type === 0) {
        this.events_form.events.splice(index, 1);
        return;
      }else if (type === -1){
        let flag = true;
        this.$refs.form.validateField('end_at',function (e,c) {
          flag = !e;
        });
        this.$refs.form.validateField('start_at',function (e) {
          flag = !flag?false:!e;
        });
        if (!flag) return;
      }
      this.events_form.events.push({
        startTime: "",
        endTime: "",
        name: "",
        key: Date.now()
      });
    },
    /**
     * @description 当时间被更改 渲染事件中的数据
     */
    onTimeChange(ev,edit,callback=()=>{}) {
      let {start_at, end_at} = this.form;
      if (!start_at) this.form.end_at = null;
      if (start_at && end_at) {
        let flag = true;
        this.$refs.form.validateField('end_at', function (e) {
          flag = !e;
        });
        this.$refs.form.validateField('start_at', function (e) {
          flag = !e;
        });
        if (edit) flag = true;
        this.events_form.events.forEach(item=>{
          item.startTime = item.endTime = null;
        })
        if (flag) {
          this.getEventData(callback)
        } else {
          this.startTimes = [];
          this.endTimes = []
        }
      }
    },
    getEventData(callback=()=>{}) {
      if(this.loadingWatch) return
      this.loadingWatch = true
      let {start_at, end_at, school_id, grade_id} = this.form;
      this.$_axios.post("/school-calendar/event-datetime", { start_at, end_at, school_id, grade_id }).then(res => {
        let { data } = res.data;
        this.startTimes = data;
        this.timeOptions = this.startTimes;
        callback();
      }).finally(() => {
        this.loadingWatch = false
      })
    },
    submit() {
      this.$refs.form.validate((res1) => {
        this.$refs.events.validate((res2) => {
          if (res1 && res2) {

            let events = this.events_form.events.map(item => {
              let startTime = item.startTime;
              let endTime = item.endTime;
              let name = item.name;
              let start_week = startTime[0];
              let start_day = startTime[1]
              let start_section = startTime[2]
              let end_week = endTime[0]
              let end_day = endTime[1]
              let end_section = endTime[2]
              return {
                name,
                start_week,
                start_day,
                start_section, end_week,
                end_day,
                end_section
              }
            });
            this.$_axios.put("/school-calendar/"+this.id, {
              ...this.form,
              events: events
            }).then(res=>{
              this.$message({
                type:"success",
                message:res.data.error.errorUserMsg,
                showClose:true
              });
              this.$router.back();
            })
          }
        })
      });
    },
    /**
     * @description 更改事件中时间
     * @param type 0,开始时间 , 1 结束时间
     * @param ev 原生参数
     * @param index 下标
     * @param {boolean}isInit  初始化
     */
    changeTimes(type,ev,index,isInit=false){
      let evNum = ev.map(item=>Number(item));
      let end = this.countEndTimes(ev);
      if (!type){
        if (!isInit)
          this.events_form.events[index].endTime = null;
        if (evNum[0] === 0) {
          this.endTimes[index] = [end [0]];
        }else {
          this.endTimes[index] = end;
        }
      }
    },
    /**
     * @description 计算结束时间数据
     * @param ev
     * @return {Array}
     */
    countEndTimes(ev){
      let end = JSON.parse(JSON.stringify(this.startTimes));
      let start = this.startTimes;
      let nums = [];
      let eachObj = (obj,_index = 0)=>{
        if (!obj)return;
        for (let i=0;i<obj.length;i++){
          let item = obj[i];
          let value = item.value;
          if (value === ev[_index]){
            nums.push(i);
            eachObj(item.children,++_index);
            break;
          }
        }
      }
      if (start && start.length!==0){
        eachObj(start);
        end.splice(0,nums[0]);
        end[0].children.splice(0,nums[1]);
        end[0].children[0].children.splice(0,nums[2]);
      }

      return end;
    }
  }
}
</script>

<style scoped lang="scss">
.add{
  padding: 20rem ;
  .events{
    .event{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      margin-bottom: 20rem;
      .eventInfo{
        width: 80%;
        display: flex;
        flex: 1;
        align-items: center;
        .form-item{
          margin-bottom: 0;
        }
        >span{
          white-space: nowrap;
          padding: 0 15rem;
          font-size: 14rem;
        }
      }
      .eventBtn{
        width: 90rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .btn{
        border-radius: 50%;
        width: 30rem;
        height: 30rem;
        text-align: center;
        line-height: 30rem;
        cursor: pointer;
        margin-left: 15rem;
        border: 1rem solid #666666;
      }
    }
  }
  .buttons{
    margin-top: 100rem;
  }
  .loading{
    text-align: center;
    margin: 25rem;
    color: #cccccc;
  }
}
</style>
